<template>
  <div class="block-divider" />
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .block-divider {
    width: 100%;
    border-bottom: 1px solid $divider;
    margin: rem(40) 0;
  }
</style>
